declare var rxjs: any;
import { IrscAbstractClass } from '../core/irsc-abstract-class';
import {
    IControls,
    IPathwayData,
    IPathwayState,
    IrscPathwayState,
} from '../core/irsc-pathway-state';

export class IrscPathwayControlsComponent extends IrscAbstractClass {
    static get observedAttributes() {
        return ['title'];
    }

    private template: any;
    private toggleElm: any;
    private notesElm: any;
    private notesPanelElm: any;
    private printElm: any;
    private infoElm: any;
    private infoModalElm: any;
    private printWindowElm: any;
    private state: IrscPathwayState;
    // create a local scoped copy the control state to help with binding
    private controls: any;
    // temporary.... just store the current track for printing tests
    // setup a component to allow custom printing options
    private currentTrack: string = 'fullTime';
    constructor() {
        super();
        const self: any = this;
        this.template = window.document.querySelector(
            '#irsc-pathway-ce-controls-tmpl'
        );
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(this.template.content.cloneNode(true));
        this.toggleElm = this.shadowRoot.querySelector('input#toggle');
        this.notesElm = this.shadowRoot.querySelector('#notes');
        this.notesPanelElm = this.shadowRoot.querySelector('#notes-panel');
        this.printElm = this.shadowRoot.querySelector('#print');
        this.infoElm = this.shadowRoot.querySelector('#info');
        this.infoModalElm = this.shadowRoot.querySelector('#info-modal');
        this.printWindowElm = this.shadowRoot.querySelector('#print-window');
        self.slotTitle = this.shadowRoot.querySelector('slot[name="title"]');
        self.slotMainnotes = this.shadowRoot.querySelector(
            'slot[name="mainnotes"]'
        );
        self.slotExtranotes = this.shadowRoot.querySelector(
            'slot[name="extranotes"]'
        );
        self.slotInstitution = this.shadowRoot.querySelector(
            'slot[name="institution"]'
        );
        self.slotGuidedpathway = this.shadowRoot.querySelector(
            'slot[name="guidedpathway"]'
        );
        self.slotMetamajor = this.shadowRoot.querySelector(
            'slot[name="metamajor"]'
        );
        self.slotLevel = this.shadowRoot.querySelector('slot[name="level"]');

        const getState = new CustomEvent('request-pathway-state', {
            detail: { state: null },
            bubbles: true,
            composed: true,
        });

        this.shadowRoot.dispatchEvent(getState);
        this.state = getState.detail.state;
    }

    // tslint:disable-next-line:cognitive-complexity
    public connectedCallback() {
        // tslint:disable-next-line:no-duplicate-string
        if (!this.toggleElm.hasOwnProperty('aria-pressed')) {
            this.toggleElm.setAttribute('aria-pressed', 'false');
        }

        if (!this.notesElm.hasOwnProperty('aria-pressed')) {
            this.notesElm.setAttribute('aria-pressed', 'false');
        }

        const self: any = this;
        rxjs.combineLatest([
            this.state.currentTrack,
            this.state.state,
            this.state.controls,
        ])
            .pipe(
                rxjs.operators.filter(
                    ([currentTrack, state, controls]: [
                        string,
                        IPathwayState,
                        IControls
                    ]) => currentTrack && state && controls
                )
            )
            .subscribe(
                ([currentTrack, state, controls]: [
                    string,
                    IPathwayState,
                    IControls
                ]) => {
                    this.printWindowElm.innerHTML = '';
                    const pathway: any = window.document.createElement(
                        'irsc-pathway-ce-print'
                    );
                    this.printWindowElm.appendChild(pathway);
                    this.controls = controls;
                    this.currentTrack = currentTrack;
                    self.slotTitle.innerText = controls.title;
                    if (controls.hasNotes) {
                        self.slotMainnotes.innerText =
                            state.data[currentTrack].notes[0].main;
                        if (state.data[currentTrack].notes[1]) {
                            self.slotExtranotes.innerText =
                                state.data[currentTrack].notes[1].extra;
                        }
                    }
                    self.slotInstitution.innerText =
                        state.data[currentTrack].institution;
                    self.slotGuidedpathway.innerText =
                        state.data[currentTrack].guidedPathway;
                    self.slotMetamajor.innerText =
                        state.data[currentTrack].metaMajor;
                    self.slotLevel.innerText =
                        state.data[currentTrack].level === 'VC'
                            ? 'CT (Career Training)'
                            : state.data[currentTrack].level;

                    this.updateNotesDisplay(controls.hasNotes);
                    this.updateToggleDisplay(controls.hasPartTime);
                    pathway.props = {
                        state,
                        currentTrack,
                        institution: state.data[currentTrack].institution || '',
                        program: state.data[currentTrack].title || '',
                        metamajor: state.data[currentTrack].metaMajor || '',
                        guidedpathway:
                            state.data[currentTrack].guidedPathway || '',
                        mainnotes: state.data[currentTrack].notes[0]
                            ? state.data[currentTrack].notes[0].main
                            : '',
                        extranotes: state.data[currentTrack].notes[1]
                            ? state.data[currentTrack].notes[1].extra
                            : '',
                    };

                    this.printElm.addEventListener('click', (e: any) => {
                        this.print();
                    });
                }
            );

        this.toggleElm.addEventListener('click', function (e: any) {
            if (this.checked) {
                self.toggleElm.setAttribute('aria-pressed', 'true');
            } else {
                self.toggleElm.setAttribute('aria-pressed', 'false');
            }

            try {
                self.controls.title = !this.checked
                    ? 'Full-time (Sample)'
                    : 'Part-time (Sample)';
            } catch (error) {
                console.log(`Could not determin the title for controls.`);
            }

            self.state.updateControls(self.controls);
            self.state.updateCurrentTrack(this.checked);
        });

        // tslint:disable-next-line: only-arrow-functions
        this.infoElm.setAttribute('aria-pressed', 'false');
        // tslint:disable-next-line: only-arrow-functions
        this.infoElm.addEventListener('click', function (e: any) {
            // fas fa-times -> fas fa-ellipsis-v
            self.toggleInfo(e);
        });
    }

    public disconnectedCallback() {
        this.toggleElm.removeEventListener('click');
        this.notesElm.removeEventListener('click');
        this.notesElm.removeEventListener('mouseenter');
        this.notesElm.removeEventListener('mouseleave');
    }

    private toggleInfo(e: any) {
        const self: any = this;
        const isPressed = self.infoElm.getAttribute('aria-pressed') === 'true';
        if (!isPressed) {
            self.infoElm.setAttribute('aria-pressed', 'true');
            self.infoElm.querySelector('i').classList.remove('fa-ellipsis-v');
            self.infoElm.querySelector('i').classList.add('fa-times');
            self.infoModalElm.classList.remove('hide');
        } else {
            self.infoElm.setAttribute('aria-pressed', 'false');
            self.infoElm.querySelector('i').classList.remove('fa-times');
            self.infoElm.querySelector('i').classList.add('fa-ellipsis-v');
            self.infoModalElm.classList.add('hide');
        }
    }
    private updateNotesDisplay(hasNotes: boolean) {
        const self: any = this;

        if (!hasNotes) {
            this.notesElm.classList.add('hide');
        } else {
            // tslint:disable-next-line:only-arrow-functions
            this.notesElm.addEventListener('click', function (e: any) {
                const isPressed =
                    self.notesElm.getAttribute('aria-pressed') === 'true';
                if (!isPressed) {
                    self.notesElm.setAttribute('aria-pressed', 'true');
                    self.notesElm
                        .querySelector('i')
                        .classList.remove('far', 'fa-bell');
                    self.notesElm
                        .querySelector('i')
                        .classList.add('fas', 'fa-bell', 'active');
                    self.notesPanelElm.classList.remove('hide');
                } else {
                    self.notesElm.setAttribute('aria-pressed', 'false');
                    self.notesElm
                        .querySelector('i')
                        .classList.remove('fas', 'fa-bell', 'active');
                    self.notesElm
                        .querySelector('i')
                        .classList.add('far', 'fa-bell');
                    self.notesPanelElm.classList.add('hide');
                }
            });
        }
    }
    private updateToggleDisplay(hasPartTime: boolean) {
        if (!hasPartTime) {
            this.shadowRoot
                .querySelector('#track-toggle')
                .classList.add('hide');
            this.shadowRoot
                .querySelector('.part-time-label')
                .classList.add('hide');
        }
    }

    private print() {
        const printDataElem: any = this.printWindowElm.querySelector(
            'irsc-pathway-ce-print'
        );
        printDataElem.print();
    }
}
