declare var rxjs: any;
import { IrscPathwayState } from 'ts/core/irsc-pathway-state';
import { IrscAbstractClass } from '../core/irsc-abstract-class';

export class IrscPathwayPaginationComponent extends IrscAbstractClass {
    static get observedAttributes() {
        return [];
    }

    public nextBtn: any;
    public previousBtn: any;
    private pagination: any;
    private paginationState: any = {
        fullTime: {
            page: 1
        },
        partTime: {
            page: 1
        }
    };

    private currentTrack: string;
    private totalPages: number;
    private state: IrscPathwayState;

    constructor() {
        super();
        this.pagination = window.document.querySelector(
            '#irsc-pathway-ce-pagination-tmpl'
        );
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(this.pagination.content.cloneNode(true));
        this.pagination = this.shadowRoot.querySelector('#pages');
        this.nextBtn = this.shadowRoot.querySelector('#next');
        this.previousBtn = this.shadowRoot.querySelector('#previous');

        this.nextBtn.addEventListener('click', (e: any) => this.next(e));
        this.previousBtn.addEventListener('click', (e: any) =>
            this.previous(e)
        );

        const getState = new CustomEvent('request-pathway-state', {
            detail: { state: null },
            bubbles: true,
            composed: true
        });

        this.shadowRoot.dispatchEvent(getState);
        this.state = getState.detail.state;
    }

    public connectedCallback() {
        rxjs.combineLatest([this.state.currentTrack, this.state.pagination])
            .pipe(
                rxjs.operators.filter(
                    ([currentTrack, pagination]: any) =>
                        currentTrack && pagination
                )
            )
            .subscribe(([currentTrack, pagination]: any) => {
                this.currentTrack = currentTrack;
                this.paginationState = pagination;
                this.totalPages = this.paginationState[
                    this.currentTrack
                ].totalPages;
                this.renderPagination(this.paginationState[this.currentTrack]);
            });
    }

    public renderPagination(pagination: any) {
        let pages = '';

        for (let i = 1; i <= pagination.totalPages; i++) {
            pages += `<div id='page-${i}' class="page${
                i === pagination.currentPage ? ' active' : ''
            }"><i class="fas fa-circle"></i></div>`;
        }

        this.pagination.innerHTML = pages;
        const pagesList = this.pagination.querySelectorAll('div[id^="page-"]');
        pagesList.forEach((page: any, idx: number) => {
            page.addEventListener('click', (e: any) => {
                this.paginationState[this.currentTrack].currentPage = idx + 1;
                this.state.updatePagination(this.paginationState);
            });
        });
        this.updatePreviousNext();
    }

    public next(e: any) {
        e.preventDefault();
        if (
            this.paginationState[this.currentTrack].currentPage >=
            this.totalPages
        ) {
            return;
        }
        this.paginationState[this.currentTrack].currentPage += 1;
        this.state.updatePagination(this.paginationState);
    }

    public previous(e: any) {
        e.preventDefault();
        if (this.paginationState[this.currentTrack].currentPage <= 1) {
            return;
        }
        this.paginationState[this.currentTrack].currentPage -= 1;
        this.state.updatePagination(this.paginationState);
    }

    private updatePreviousNext() {
        this.nextBtn.removeAttribute('disabled');
        this.nextBtn.classList.remove('hide');
        this.previousBtn.removeAttribute('disabled');
        this.previousBtn.classList.remove('hide');

        if (
            this.paginationState[this.currentTrack].currentPage >=
            this.totalPages
        ) {
            this.nextBtn.setAttribute('disabled', 'disabled');
            this.nextBtn.classList.add('hide');
        }

        if (this.paginationState[this.currentTrack].currentPage <= 1) {
            this.previousBtn.setAttribute('disabled', 'disabled');
            this.previousBtn.classList.add('hide');
        }
    }
}
