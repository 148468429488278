declare var window: any;
declare var rxjs: any;

export interface IPathway {
    code: string;
    data: any;
    currentTrack: string;
    hasPartTime: boolean;
    showNotes: boolean;
    isClockHour: boolean;
}

export class IrscPathwayService {
    public clockHoursFilter: any;
    public pathways: any = new Map();

    private unsubscribe: any = new rxjs.Subject();
    private pathwayUrl = 'https://irsc-api2.azurewebsites.net/gp/tracks/';
    private filterUrl = 'https://irsc-api2.azurewebsites.net/gp/clock-hours';
    constructor() {
        // console.(this.http);
        if (!window.hasOwnProperty('irsc')) {
            window.irsc = {};
        }

        if (!window.irsc.hasOwnProperty('PathwayService')) {
            window.irsc.PathwayService = this;
        }

        this.pathways = window.irsc.PathwayService.pathways;

        if (!this.clockHoursFilter) {
            this.getClockHoursFilter();
        }
    }

    public async getClockHoursFilter() {
        this.clockHoursFilter = await fetch(this.filterUrl)
            .then((res: any) => res.json())
            .then((res: any) => res.filter)
            .catch((err: any) => {
                console.log(
                    'There was an error retrieving the clock hours filter.'
                );
                console.log(err.message);
            });
    }

    public getPathway(code: string) {
        // setup new subject for pathway and a clock hour filter
        if (!this.pathways.hasOwnProperty(code)) {
            this.pathways.set(
                code,
                new rxjs.BehaviorSubject(
                    fetch(`${this.pathwayUrl}${code}`)
                        .then((res: any) => res.json())
                        .then(async (res: any) => {
                            const filter = await this.clockHoursFilter;
                            let isClockHour = false;
                            try {
                                isClockHour = filter.includes(+code);
                            } catch (e) {
                                console.log(
                                    `Could not determine if is clock hour for pathway: ${code}`
                                );
                            }

                            const _pathway: IPathway = {
                                code,
                                data: {
                                    fullTime: null,
                                    partTime: null,
                                },
                                currentTrack: 'fullTime',
                                hasPartTime: false,
                                showNotes: false,
                                isClockHour,
                            };

                            // set whether the current pathway has a part time track or not
                            // also setup the data to just hold the tracks
                            for (const track of res) {
                                if (track.isPartTime) {
                                    _pathway.hasPartTime = true;
                                    _pathway.data.partTime = track;
                                    continue;
                                }
                                _pathway.data.fullTime = track;
                            }

                            return _pathway;
                        })
                        .catch((err: any) => {
                            console.log(
                                `There was an error in retrieving pathway request for code: ${code}`
                            );
                            console.log(err);
                        })
                )
            );
        }

        return this.pathways.get(code).pipe(
            rxjs.operators.takeUntil(this.unsubscribe),
            rxjs.operators.switchMap((data: any) => rxjs.from(data))
        );
    }

    public disconnectedCallback() {
        this.unsubscribe.next(null);
    }
}
