declare var rxjs: any;
import { IrscPathwayState } from 'ts/core/irsc-pathway-state';
import { IrscAbstractClass } from '../core/irsc-abstract-class';

export class IrscPathwayDataPageComponent extends IrscAbstractClass {
    get observedAttributes() {
        return [];
    }

    private template: any;
    private page: any;
    private slotPageData: any;
    private state: IrscPathwayState;
    private unsubscribe: any = new rxjs.Subject();
    constructor() {
        super();
        this.template = window.document.querySelector(
            '#irsc-pathway-ce-data-page-tmpl'
        );
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(this.template.content.cloneNode(true));
        this.page = this.shadowRoot.querySelector('#page-content');
        this.slotPageData = this.shadowRoot.querySelector(
            'slot[name="page-data"]'
        );

        const getState = new CustomEvent('request-pathway-state', {
            detail: { state: null },
            bubbles: true,
            composed: true
        });

        this.shadowRoot.dispatchEvent(getState);
        this.state = getState.detail.state;
    }

    public connectedCallback() {
        rxjs.combineLatest([this.state.state, this.state.currentPage])
            .pipe(
                rxjs.operators.takeUntil(this.unsubscribe),
                rxjs.operators.distinctUntilChanged(),
                rxjs.operators.filter(
                    ([state, currentPage]: any) => state && currentPage
                )
            )
            .subscribe(([state, currentPage]: any) => {
                this.page.innerHTML = '';
                const courses: any = window.document.createDocumentFragment();
                // override the semester title for now until further notice
                // currentPage.title = 'Suggested Offering';
                const title: string = `${currentPage.title} (${state.total.title}: ${currentPage.totalCreditHours})`;
                const strong: any = window.document.createElement('strong');

                strong.innerText = title;
                courses.appendChild(strong);

                for (const course of currentPage.courses) {
                    const courseElm: any = window.document.createElement(
                        'irsc-pathway-ce-data-page-course'
                    );

                    courseElm.courseid = course.courseId;
                    courseElm.credits = course.creditHours;
                    courseElm.title = course.title;
                    courseElm.prerequisites = course.prerequisites;
                    courseElm.isRequired = !!course.isRequired
                        ? course.isRequired
                        : false;
                    courseElm.setAttribute('aria-label', course.title);
                    courses.appendChild(courseElm);
                }
                this.page.appendChild(courses);
            });
        // this.state.currentPage
        //     .pipe(
        //         rxjs.operators.takeUntil(this.unsubscribe),
        //         rxjs.operators.distinctUntilChanged(),
        //         rxjs.operators.filter((currentPage: any) => currentPage)
        //     )
        //     .subscribe((currentPage: any) => {
        //         this.page.innerHTML = '';
        //         console.log(currentPage);
        //         const courses: any = window.document.createDocumentFragment();
        //         const title: string = `${currentPage.title} (${currentPage.totalCreditHours})`;
        //         const strong: any = window.document.createElement('strong');

        //         strong.innerText = title;
        //         courses.appendChild(strong);

        //         for (const course of currentPage.courses) {
        //             const courseElm: any = window.document.createElement(
        //                 'irsc-pathway-ce-data-page-course'
        //             );

        //             courseElm.courseid = course.courseId;
        //             courseElm.credits = course.creditHours;
        //             courseElm.title = course.title;
        //             courseElm.prerequisites = course.prerequisites;
        //             courseElm.isRequired = !!course.isRequired
        //                 ? course.isRequired
        //                 : false;
        //             courseElm.setAttribute('aria-label', course.title);
        //             courses.appendChild(courseElm);
        //         }
        //         this.page.appendChild(courses);
        //     });
    }

    public disconnectedCallback() {
        this.unsubscribe.next(null);
    }
}
