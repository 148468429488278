import { IrscAbstractClass } from '../core/irsc-abstract-class';
export class IrscPathwayPrintComponent extends IrscAbstractClass {
    get props() {
        return this._props;
    }

    set props(value: any) {
        this._props = value;
        this.createPathway();
    }

    private template: any;
    private _props: any;

    constructor() {
        super();
        this.template = window.document.querySelector(
            '#irsc-pathway-ce-print-tmpl'
        );
    }

    public print() {
        const html: any = this.processChildren(this.shadowRoot);
        const printWindow: any = window.open(
            '',
            'PRINT',
            'height=800,width=1200'
        );

        printWindow.document.write(html);
        printWindow.document.querySelector('head').innerHTML =
            // tslint:disable-next-line:max-line-length
            '<link rel="stylesheet" href="https://www.irsc.edu/_resources/css/custom-pathways-print.min.css" media="print">';
        // tslint:disable-next-line:max-line-length
        // '<link rel="stylesheet" href="http://localhost:3000/build/css/custom-pathways-print.min.css" media="print">';
        printWindow.document.close();
        printWindow.focus();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 500);
    }

    private processChildren(parent: any) {
        let html: string = '';
        for (const child of parent.children) {
            html += this.processChild(child);
        }
        return html;
    }

    private processChild(child: any) {
        if (child.children && child.children.length > 0) {
            // return this.processChildren(child);
            child.innerHTML = this.processChildren(child);
            return child.outerHTML;
        }

        if (child.shadowRoot) {
            return this.processChildren(child.shadowRoot);
        }

        return child.outerHTML;
    }

    private createPathway() {
        const pathway: any = this.props;
        const currentTrack = pathway.state.data[pathway.currentTrack];
        // console.log(currentTrack);
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(this.template.content.cloneNode(true));
        this.setAttribute('id', 'print-window');
        const self: any = this.renderComp(this, this.props);
        const semestersFragment = window.document.createDocumentFragment();
        const semestersElm: any = self.shadowRoot.querySelector('#semesters');
        for (const semester of currentTrack.semesters) {
            semester.creditsHoursTitle = pathway.state.total.isClockHour
                ? 'Clock Hours'
                : 'Credits';
            const semesterComp: any = this.createSemester(semester);
            semestersFragment.appendChild(semesterComp);
        }
        semestersElm.appendChild(semestersFragment);
    }

    private createSemester(semester: any) {
        // setup the custom elements but clone then later
        let semesterComp: any = window.document.createElement(
            'irsc-pathway-ce-print-semester'
        );
        semesterComp = this.renderComp(semesterComp, semester);
        const coursesFragment = window.document.createDocumentFragment();
        // create a courses fragment to collect all the courses then inject
        const coursesElm: any = semesterComp.shadowRoot.querySelector(
            '#courses'
        );

        for (const course of semester.courses) {
            coursesFragment.appendChild(this.createCourse(course));
        }

        coursesElm.appendChild(coursesFragment);

        return semesterComp;
    }

    private createCourse(course: any) {
        let courseComp: any = window.document.createElement(
            'irsc-pathway-ce-print-course'
        );
        // modify the isRequired from boolean to string
        // true: required
        // false: ''
        course.isRequired = course.isRequired ? 'required' : '';
        courseComp = this.renderComp(courseComp, course);
        // create a prerequisites fragment to collect; all; the; courses; then; inject;

        const prerequisitesFragment: any = window.document.createDocumentFragment();

        for (const prereq of course.prerequisites) {
            const prq: any = window.document.createElement('p');
            prq.innerText = prereq;
            prerequisitesFragment.appendChild(prq);
        }
        courseComp.shadowRoot
            .querySelector('#prerequisites')
            .appendChild(prerequisitesFragment);

        return courseComp;
    }

    private renderComp(comp: any, data: any) {
        let html = comp.shadowRoot.innerHTML;
        for (const prop of Object.keys(data)) {
            const token = `{{\\s+${prop}\\s+}}`;
            // console.log(token);
            html = html.replace(new RegExp(token, 'gim'), data[prop]);
        }
        comp.shadowRoot.innerHTML = html;
        return comp;
    }
}
