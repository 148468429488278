import { IrscAbstractClass } from '../core/irsc-abstract-class';
import { IPathwayState, IrscPathwayState } from '../core/irsc-pathway-state';

export class IrscPathwayTotalComponent extends IrscAbstractClass {
    static get observedAttributes() {
        return ['title', 'total'];
    }

    private template: any;
    private state: IrscPathwayState;

    constructor() {
        super();
        const self: any = this as any;
        this.template = window.document.querySelector(
            '#irsc-pathway-ce-total-tmpl'
        );
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(this.template.content.cloneNode(true));
        self.slotTitle = this.shadowRoot.querySelector('slot[name="title"]');
        self.slotTotal = this.shadowRoot.querySelector('slot[name="total"]');

        const getState = new CustomEvent('request-pathway-state', {
            detail: { state: null },
            bubbles: true,
            composed: true
        });

        this.shadowRoot.dispatchEvent(getState);
        this.state = getState.detail.state;
    }

    public connectedCallback() {
        const _self: any = this;
        this.state.state.subscribe((state: IPathwayState) => {
            _self.total = state.total.total;
            _self.title = state.total.title;
        });
    }
}
