declare var rxjs: any;
import { IrscAbstractClass } from '../core/irsc-abstract-class';
import { IrscPathwayState } from '../core/irsc-pathway-state';

export class IrscPathwayDataComponent extends IrscAbstractClass {
    private template: any;
    private code: string;
    private state: IrscPathwayState;
    private unsubscribe: any = new rxjs.Subject();
    constructor() {
        super();
        this.template = window.document.querySelector(
            '#irsc-pathway-ce-data-tmpl'
        );
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(this.template.content.cloneNode(true));

        const getCode = new CustomEvent('request-pathway-code', {
            detail: { code: null },
            bubbles: true,
            composed: true
        });

        this.shadowRoot.dispatchEvent(getCode);
        this.code = getCode.detail.code;

        const getState = new CustomEvent('request-pathway-state', {
            detail: { state: null },
            bubbles: true,
            composed: true
        });

        this.shadowRoot.dispatchEvent(getState);
        this.state = getState.detail.state;
    }

    public disconnectedCallback() {
        this.unsubscribe.next();
    }
}
