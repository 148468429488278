declare var Handlebars: any;
import { IrscAbstractClass } from '../core/irsc-abstract-class';

export class IrscPathwayDataPageCourseComponent extends IrscAbstractClass {
    static get observedAttributes() {
        return ['courseid', 'title', 'credits', 'prerequisites'];
    }

    get props() {
        return this._props;
    }

    set props(value: any) {
        this._props = value;
    }

    private template: any;
    private btnCollapse: any;
    private prerequisitesElm: any;
    private render: any;
    private _props: any;
    constructor() {
        super();
        const self: any = this;
        this.template = window.document.querySelector(
            '#irsc-pathway-ce-data-page-course-tmpl'
        );
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(this.template.content.cloneNode(true));
        self.slotCourseid = this.shadowRoot.querySelector(
            'slot[name="courseid"]'
        );
        self.slotTitle = this.shadowRoot.querySelector('slot[name="title"]');
        self.slotPrerequisites = this.shadowRoot.querySelector(
            'slot[name="prerequisites"]'
        );
        self.slotCredits = this.shadowRoot.querySelector(
            'slot[name="credits"]'
        );
    }

    public connectedCallback() {
        const self: any = this;

        this.render = Handlebars.compile(this.shadowRoot.innerHTML);
        // at this point, in order to do templating, we have to re-render the element...:(
        this.shadowRoot.innerHTML = this.render({
            title: self.title
        });

        this.prerequisitesElm = this.shadowRoot.querySelector('#prerequisites');
        if (self.prerequisites.length > 0) {
            this.prerequisitesElm.classList.remove('hide');
        }
        this.btnCollapse = this.shadowRoot.querySelector('#btn-collapse');
        // tslint:disable-next-line:no-duplicate-string
        if (!this.btnCollapse.hasOwnProperty('aria-expanded')) {
            this.btnCollapse.setAttribute('aria-expanded', 'false');
        }
        if (self.isRequired) {
            this.btnCollapse.classList.add('required');
        }
        this.btnCollapse.addEventListener('click', (e: any) => {
            this.handleClickEvent(e);
        });
    }

    private handleClickEvent(e: any) {
        this.btnCollapse.classList.toggle('active');
        const isExpanded =
            this.btnCollapse.getAttribute('aria-expanded') === 'true';
        if (isExpanded) {
            this.btnCollapse.setAttribute('aria-expanded', 'false');
        } else {
            this.btnCollapse.setAttribute('aria-expanded', 'true');
        }
        const content: any = this.shadowRoot.querySelector('#content');

        if (content.style.display === 'block') {
            content.style.display = 'none';
        } else {
            content.style.display = 'block';
        }
    }
}
