declare var rxjs: any;
import { IrscAbstractClass } from '../core/irsc-abstract-class';
import { IPathway, IrscPathwayService } from '../core/irsc-pathway-service';
import { IPathwayState, IrscPathwayState } from '../core/irsc-pathway-state';
export class IrscPathwayComponent extends IrscAbstractClass {
    static get observedAttributes() {
        return ['code', 'title', 'level'];
    }
    private data: any;
    private pathwayService: IrscPathwayService = new IrscPathwayService();
    private unsubscribe: any = new rxjs.Subject();
    private template: any;
    private state: IrscPathwayState;

    constructor() {
        super();
        // ts can be annoying, se we create a reference to this class
        // slots getters and setters are dynamically added to the class
        const self: any = this;

        this.template = window.document.querySelector('#irsc-pathway-ce-tmpl');
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(this.template.content.cloneNode(true));
        self.slotCode = this.shadowRoot.querySelector('slot[name="code"]');
        self.slotTitle = this.shadowRoot.querySelector('slot[name="title"]');
        self.slotLevel = this.shadowRoot.querySelector('slot[name="level"]');
        this.data = this.pathwayService.getPathway(self.code);
        this.state = new IrscPathwayState(self.code);

        this.shadowRoot.addEventListener(
            'request-pathway-code',
            (event: any) => {
                event.detail.code = self.code;
            }
        );
        this.shadowRoot.addEventListener(
            'request-pathway-state',
            (event: any) => {
                event.detail.state = this.state;
            }
        );
    }

    public connectedCallback() {
        this.data
            .pipe(
                rxjs.operators.takeUntil(this.unsubscribe),
                rxjs.operators.filter(
                    (pathway: IPathway) => pathway && pathway.code !== null
                ),
                rxjs.operators.map((pathway: IPathway) => {
                    // tslint:disable-next-line: prefer-immediate-return
                    let hasNotes = false;
                    try {
                        hasNotes =
                            pathway.data[pathway.currentTrack].notes.length > 0;
                    } catch (e) {
                        console.log(`No notes for pathway: ${pathway.code}`);
                    }

                    let totalFullTimePages = 0;
                    let totalPartTimePages = 0;
                    let totalTitle = 'Credits';
                    try {
                        totalTitle = pathway.isClockHour
                            ? 'Clock Hours'
                            : 'Credits';
                    } catch (e) {
                        console.log(
                            `Could not determine title (credit/clock hour) for pathway: ${pathway.code}`
                        );
                    }

                    try {
                        totalFullTimePages =
                            pathway.data.fullTime.semesters.length;
                    } catch (e) {
                        console.log(
                            `No fullTime semesters for ${pathway.code}`
                        );
                    }

                    try {
                        totalPartTimePages =
                            pathway.data.partTime.semesters.length;
                    } catch (e) {
                        console.log(
                            `No partTime semesters for ${pathway.code}`
                        );
                    }

                    let total = 0;

                    try {
                        total = pathway.data[pathway.currentTrack].creditHours;
                    } catch (e) {
                        console.log(
                            `Could not determin the creditHours total for pathway: ${pathway.code}`
                        );
                    }

                    return {
                        code: pathway.code,
                        data: pathway.data,
                        controls: {
                            hasNotes,
                            showNotes: false,
                            showPartTime: false,
                            hasPartTime: pathway.hasPartTime,
                            title: pathway.hasPartTime
                                ? 'Full-time (Sample)'
                                : 'Sample',
                        },
                        currentTrack: pathway.currentTrack,
                        pagination: {
                            fullTime: {
                                currentPage: 1,
                                totalPages: totalFullTimePages,
                            },
                            partTime: pathway.hasPartTime
                                ? {
                                      currentPage: 1,
                                      totalPages: totalPartTimePages,
                                  }
                                : null,
                        },
                        total: {
                            title: totalTitle,
                            total,
                            isClockHour: pathway.isClockHour,
                        },
                    };
                })
            )
            .subscribe((data: IPathwayState) => {
                this.state.updateState(data);
                this.disconnectedCallback();
            });
    }

    public disconnectedCallback() {
        this.unsubscribe.next();
    }
}
