declare var rxjs: any;
export interface IPathwayData {
    fullTime: any;
    partTime: any;
}

export interface IControls {
    showPartTime: boolean;
    hasPartTime: boolean;
    showNotes: boolean;
    hasNotes: boolean;
    title: string;
}

export interface IPaginationPage {
    currentPage: number;
    totalPages: number;
}

export interface IPagination {
    fullTime: IPaginationPage;
    partTime?: IPaginationPage;
}

export interface ITotal {
    title: string;
    total: number;
    isClockHour: boolean;
}

export interface IPathwayState {
    code: string;
    controls: IControls;
    currentTrack: string;
    data: IPathwayData;
    pagination: IPagination;
    total: ITotal;
}

export class IrscPathwayState {
    public state: any;
    public controls: any;
    public pagination: any;
    public currentPage: any;
    public currentTrack: any;
    private _unsubscribe: any = new rxjs.Subject();

    constructor(code: string) {
        // setup _state filtering...
        const INITIAL_STATE: IPathwayState = {
            code,
            controls: {
                showPartTime: false,
                hasPartTime: false,
                showNotes: false,
                hasNotes: false,
                title: 'Full-time (Sample)'
            },
            currentTrack: 'fullTime',
            data: {
                fullTime: null,
                partTime: null
            },
            pagination: {
                fullTime: {
                    currentPage: 1,
                    totalPages: 1
                }
            },
            total: {
                title: 'Credits',
                total: 0,
                isClockHour: false
            }
        };

        this.state = new rxjs.BehaviorSubject(INITIAL_STATE).pipe(
            rxjs.operators.filter(
                (_state: IPathwayState) => _state && _state.data.fullTime
            )
        );
        this.controls = new rxjs.BehaviorSubject(null);
        this.pagination = new rxjs.BehaviorSubject(null);
        this.currentPage = new rxjs.BehaviorSubject(null);
        this.currentTrack = new rxjs.BehaviorSubject('fullTime');

        this.setupFiltering();
    }

    public updateState(state: IPathwayState) {
        this.state.next(state);
    }

    public updateControls(state: IControls) {
        this.controls.next(state);
    }

    public updatePagination(state: IPagination) {
        this.pagination.next(state);
    }

    public updateCurrentTrack(value: boolean) {
        this.currentTrack.next(!value ? 'fullTime' : 'partTime');
    }

    public freeze() {
        this._unsubscribe.next(null);
    }

    private setupFiltering() {
        rxjs.combineLatest([
            this.state,
            this.currentTrack,
            this.controls,
            this.pagination
        ])
            .pipe(
                rxjs.operators.takeUntil(this._unsubscribe),
                rxjs.operators.distinctUntilChanged(),
                rxjs.operators.map(
                    ([state, currentTrack, controls, pagination]: any) => {
                        if (!controls) {
                            this.controls.next(state.controls);
                        }

                        if (!pagination) {
                            this.pagination.next(state.pagination);
                        }

                        if (!currentTrack) {
                            this.currentTrack.next(state.currentTrack);
                        }

                        if (
                            currentTrack &&
                            currentTrack !== state.currentTrack
                        ) {
                            state.currentTrack = currentTrack;
                        }
                        return state;
                    }
                )
            )
            .subscribe((state: IPathwayState) => {
                this.currentPage.next(
                    state.data[state.currentTrack].semesters[
                        state.pagination[state.currentTrack].currentPage - 1
                    ]
                );
            });
    }
}
