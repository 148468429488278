import { IrscAbstractClass } from '../core/irsc-abstract-class';
export class IrscPathwayPrintSemesterComponent extends IrscAbstractClass {
    private template: any;
    constructor() {
        super();
        this.template = window.document.querySelector(
            '#irsc-pathway-ce-print-semester-tmpl'
        );
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(this.template.content.cloneNode(true));
    }
}
