import { IrscPathwayComponent } from './components/irsc-pathway-component';
import { IrscPathwayControlsComponent } from './components/irsc-pathway-controls.component';
import { IrscPathwayDataComponent } from './components/irsc-pathway-data-component';
import { IrscPathwayDataPageComponent } from './components/irsc-pathway-data-page-component';
import { IrscPathwayDataPageCourseComponent } from './components/irsc-pathway-data-page-course-component';
import { IrscPathwayPaginationComponent } from './components/irsc-pathway-pagination-component';
import { IrscPathwayPrintComponent } from './components/irsc-pathway-print-component';
import { IrscPathwayPrintCourseComponent } from './components/irsc-pathway-print-course-component';
import { IrscPathwayPrintSemesterComponent } from './components/irsc-pathway-print-semester-component';
import { IrscPathwayTotalComponent } from './components/irsc-pathway-total-component';
customElements.define('irsc-pathway-ce', IrscPathwayComponent);
customElements.define('irsc-pathway-ce-total', IrscPathwayTotalComponent);
customElements.define('irsc-pathway-ce-data', IrscPathwayDataComponent);
customElements.define(
    'irsc-pathway-ce-pagination-page',
    IrscPathwayDataPageComponent
);
customElements.define(
    'irsc-pathway-ce-data-page-course',
    IrscPathwayDataPageCourseComponent
);
customElements.define('irsc-pathway-ce-controls', IrscPathwayControlsComponent);
customElements.define(
    'irsc-pathway-ce-pagination',
    IrscPathwayPaginationComponent
);
customElements.define('irsc-pathway-ce-print', IrscPathwayPrintComponent);
customElements.define(
    'irsc-pathway-ce-print-course',
    IrscPathwayPrintCourseComponent
);
customElements.define(
    'irsc-pathway-ce-print-semester',
    IrscPathwayPrintSemesterComponent
);

console.log('created irsc custom elements...');
